<template>
  <a-spin :spinning="spinning">
    <a-card :border="false">
      <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="经销商名称">
                <a-input v-model="searchData.dealerName" placeholder="请输入经销商名称" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="额度名称">
                <a-input v-model="searchData.name" placeholder="请输入额度名称" allowClear></a-input>
              </a-form-model-item>
            </a-col>
<!--            <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--              <a-form-model-item label="大区事务所">-->
<!--                <a-cascader-->
<!--                  :options="dealerList"-->
<!--                  v-model="regionalOffice"-->
<!--                  change-on-select-->
<!--                  placeholder="请选择大区/事务所"-->
<!--                  @change="onChange"-->
<!--                  :field-names="{ label: 'title', value: 'id', children: 'children' }"-->
<!--                />-->
<!--              </a-form-model-item>-->
<!--            </a-col>-->
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-item label="额度类型">
                <a-select placeholder="请选择" v-model="searchData.limitType" allowClear>
                  <a-select-option :value="1">全部商品</a-select-option>
                  <a-select-option :value="2">商品分类</a-select-option>
                  <a-select-option :value="3">指定商品</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-item label="审核状态">
                <a-select placeholder="请选择" v-model="searchData.authStatus" allowClear>
                  <a-select-option :value="0">待处理</a-select-option>
                  <a-select-option :value="1">待审核</a-select-option>
                  <a-select-option :value="2">审核通过</a-select-option>
                  <a-select-option :value="3">审核拒绝</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-item label="额度状态">
                <a-select placeholder="请选择" v-model="searchData.limitStatus" allowClear>
                  <a-select-option :value="0">未开始</a-select-option>
                  <a-select-option :value="1">使用中</a-select-option>
                  <a-select-option :value="2">即将过期</a-select-option>
                  <a-select-option :value="3">已逾期</a-select-option>
                  <a-select-option :value="4">已过期</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="有效时间">
                <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange>
              </a-form-model-item>
            </a-col>

            <a-col :xxl="6" :xl="5" :md="8" sm="2">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                <a-button type="default" @click="reset()" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div>
          <a-button v-if="isCheck" type="primary" @click="toHandler('check')">查看</a-button>
          <a-button v-if="isRecord" type="primary" @click="hanlderTest('check')" icon="copy">使用流水</a-button>
          <a-button v-if="isRepaymentRecord" type="primary" @click="hanlderTest('checkRepayment')" icon="copy">还款记录</a-button>
          <a-button v-if="isLog" type="primary" @click="hanlderTest('checkLog')" icon="file">操作日志</a-button>
<!--          <downLoad-->
<!--            method="get"-->
<!--            api="/api/order/order/orderInfo/exportSingleOrderInfo"-->
<!--            :params="{ id: 11 }"-->
<!--            name="导出.xlsx"-->
<!--            >导出</downLoad-->
<!--          >-->
        </div>
        <!-- 数据表格 -->
        <a-table
          :loading="tableLoading"
          :row-selection="rowSelection"
          :rowKey="(record) => record.id"
          :columns="columns"
          :data-source="tableData"
          bordered
          :pagination="page"
          @change="changeTable"
          :customRow="changeTableRow"
        >
          <span slot="limitType" slot-scope="text">
            <span v-if="text == 1"><a-tag color="green">全部商品</a-tag></span>
            <span v-if="text == 2"><a-tag color="cyan">商品分类</a-tag></span>
            <span v-if="text == 3"><a-tag color="blue">指定商品</a-tag></span>
          </span>
          <span slot="limitStatus" slot-scope="text">
            <a-tag color="#808080" v-if="text == 0">未开始</a-tag>
            <a-tag color="#e6a23c" v-if="text == 1">使用中</a-tag>
            <a-tag color="#ffc000" v-if="text == 2">即将过期</a-tag>
            <a-tag color="red" v-if="text == 3">已逾期</a-tag>
            <a-tag color="#333333" v-if="text == 4">已过期</a-tag>
          </span>
          <span slot="authStatus" slot-scope="text">
            <a-tag v-if="text == 0" color="#333333">待处理</a-tag>
            <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
            <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
            <a-tag v-if="text == 3" color="#67c23a">审核拒绝</a-tag>
          </span>
          <span slot="limitCode" slot-scope="text, row">
            <span @click.stop="">
              <a href="#" @click="toHandler('checkLSH', row)">{{ text }}</a>
            </span>
          </span>
          <span slot="dealerName" slot-scope="text, row">
            <span @click.stop="">
              <a href="#" @click="toHandler('checkJXS', row)">{{ text }}</a>
            </span>
          </span>
          <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="validityStart" slot-scope="text, row"> {{ row.validityStart }} ~ {{ row.validityEnd }} </span>
        </a-table>
        <!-- 信用额度 -->
        <FinanceCreditModal ref="FinanceCreditModal" @reload="getData"></FinanceCreditModal>
        <UsedDetailed ref="UsedDetailed"> </UsedDetailed>
        <OperateLog ref="OperateLog"></OperateLog>
        <RepaymentDetailed ref="RepaymentDetailed"> </RepaymentDetailed>
        <CustomerInfoCheckModal ref="CustomerInfoCheckModal" />
      </div>
    </a-card>
  </a-spin>
</template>

<script>
import { columns } from './thead/colums'
import UsedDetailed from './components/UsedDetailed'
import OperateLog from './components/OperateLog'
import RepaymentDetailed from './components/RepaymentDetailed'
import { downExcel, listLimitInfo} from './api/LimitInfoApi'
import { baseURL } from '@/utils/request'
import moment from "moment";
import { checkPermission } from '@/utils/permissions'
export default {
  name: 'limitInfo',
  components: {
    FinanceCreditModal: () => import('./components/FinanceCreditModal'),
    CustomerInfoCheckModal: () => import('../customer/components/CustomerInfoCheckModal.vue'),
    UsedDetailed,
    OperateLog,
    RepaymentDetailed
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      loading: false,
      loadingAll: false,
      verifyRemark: '',
      dealerList: [],
      regionalOffice: [],
      dataList: [],
      //上传的数据
      uploadData: [],
      //文件数据
      fileList: [],
      //上传文件地址
      uploadUrl: baseURL + '/api/dealer/dealer/dealerPaymentFrom/excelReader',
      //上传文件请求头
      headers: {
        'Authorization-Admin': this.$store.getters.token,
      },
      spinning: false,
      isCheck: checkPermission('limit:info:firm:check'),
      isRecord: checkPermission('limit:info:firm:record'),
      isRepaymentRecord: checkPermission('limit:info:firm:repaymentRecord'),
      isLog: checkPermission('limit:info:firm:log'),
      distributor_name: this.$route.params.dealerName,
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    getToken() {
      return this.$sotre.state.SET_TOKEN()
    },
  },
  mounted() {
    if (this.distributor_name) {
      this.searchData.dealerName = this.distributor_name
    }
    this.getData()
  },
  methods: {
    hanlderTest(name) {
      const _this = this
      if (_this.selectedRows.length === 0 || _this.selectedRows.length > 1) {
        return _this.$message.warning('请选择一条记录')
      }
      if (name === 'check') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.UsedDetailed.setRowData(this.selectedRows[0])
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'checkRepayment') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.RepaymentDetailed.setRowData(this.selectedRows[0])
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'checkLog') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.OperateLog.setRowData(this.selectedRows[0])
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    downItemExcel() {
      const _this = this
      const params = _this.tableData
      downExcel(params)
        .then((res) => {
          console.log('进不来')
        })
        .catch((error) => {
          const blob = new Blob([error], {
            type: 'application/vnd.ms-excel',
          })
          const objectUrl = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = objectUrl
          a.download = '收款单'
          //a.click();
          //下面这个写法兼容火狐
          a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
          window.URL.revokeObjectURL(blob)
        })
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listLimitInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))

      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        // 获取大区事务所经销商数据
        this.dealerList = res.body
      })
    },

    disabledDate(current) {
      return current && current == moment()
    },

    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      this.page.current = 1
      this.page.pageSize = 10
      this.loadingAll = false
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },

    toHandler(name,row) {
      if (name === 'checkLSH') {
        if (this.$refs.FinanceCreditModal) {
        this.$refs.FinanceCreditModal.setRowData(row, 'check')
        }
        return false
      }
      if (name === 'checkJXS') {
        const item = {
          id:row.dealerId
        }
        if (this.$refs.CustomerInfoCheckModal) {
          this.$refs.CustomerInfoCheckModal.setRowData(item, 'check')
        }
        return false
      }
      const _this = this
      if (_this.selectedRows.length === 0 || _this.selectedRows.length > 1) {
        return _this.$message.warning('请选择一条记录')
      }
      if (name === 'check') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.FinanceCreditModal.setRowData(_this.selectedRows[0], 'check')
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      switch (name) {
        case 'export':
          window.location.href = `${process.env.VUE_APP_API_BASE_URL}/api/customer/customer/financeReceipt/excel=${this.selectedRowKeys[0]}`
          break
      }
    },
  },

}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
